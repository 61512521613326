import { useStaticQuery, graphql } from "gatsby"

export const EducationImages = () => {
  const { contentfulEducationImages } = useStaticQuery(
    graphql`
      query {
        contentfulEducationImages {
          hero {
            fluid(quality: 100, maxWidth: 1440) {
              ...GatsbyContentfulFluid_tracedSVG
              aspectRatio
            }
          }
          coloringSheets {
            title
            fluid(quality: 100, maxWidth: 600) {
              ...GatsbyContentfulFluid_tracedSVG
              src
            }
            file {
              url
            }
          }
          step2 {
            fluid(quality: 100, maxWidth: 1000) {
              ...GatsbyContentfulFluid_tracedSVG
              src
            }
          }
          step3 {
            fluid(quality: 100, maxWidth: 1000) {
              ...GatsbyContentfulFluid_tracedSVG
              src
            }
          }
          carePackages {
            fluid(quality: 100, maxWidth: 1000) {
              ...GatsbyContentfulFluid_tracedSVG
              src
            }
          }
          events {
            fluid(quality: 100, maxWidth: 1000) {
              ...GatsbyContentfulFluid_tracedSVG
              src
            }
          }
        }
      }
    `
  )
  return contentfulEducationImages
}
