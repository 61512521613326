import React from "react"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import Hero from "../components/Sections/BasicHero"
import TwoColumn from "../components/Sections/TwoColumn"
import TwoColumnAlt from "../components/Sections/TwoColumnAlt"
import Carousel from "../components/Elements/ColoringSheetCarousel"
import { styled } from "linaria/react"
import { EducationImages } from "../Hooks/EducationImages"
import Support from "../components/Sections/Support"
import Social from "../components/Sections/SocialFeed"
import Subscription from "../components/Sections/Subscription"

const Heading = styled.h2`
  @media screen and (max-width: 900px) {
    margin-top: 100px;
    font-size: 35px;
  }
  @media screen and (max-width: 480px) {
    margin-top: 100px;
    font-size: 30px;
  }
  text-transform: uppercase;
  text-align: center;
  margin: auto;
  font-weight: 600;
  font-size: 45px;
  line-height: 55px;
  color: #242424;
  margin-top: 250px;
`

const Hr = styled.hr`
  @media screen and (max-width: 900px) {
    margin-bottom: 100px;
  }
  @media screen and (max-width: 480px) {
    margin-bottom: 0px;
  }
  max-width: 150px;
  margin-bottom: 100px;
`

const EducationPage = () => {
  const {
    hero,
    coloringSheets,
    step2,
    step3,
    carePackages,
    events,
  } = EducationImages()

  return (
    <Layout>
      <SEO
        title="Education"
        description="Helping children learn about the importance of supporting our troops. Fun activities, coloring sheets and thank you cards. "
      />
      <Hero
        title="Saying Thank You"
        body="A great way to get involved with K.A.T.E. a show your support for our troops is a simple thank you card! Thank you cards are a great way to get kids involved in the mission and are a fun little activity for you and your child. Follow the steps below and learn how to create and send a personalized thank you card and show your support for a soldier in need."
        cta={<></>}
        margin={"0 0 0 auto"}
        fluid={hero.fluid}
      />
      <Heading>How to Get Started</Heading>
      <Hr />
      <TwoColumnAlt
        subhead="Choose a color sheet"
        head="Step 1"
        body="Choose your favorite coloring sheet and print it out or get creative and use your own! Keep in mind that your coloring sheet should be supportive and avoid including any sensitive material."
        first={true}
      >
        <Carousel coloringSheets={coloringSheets} />
      </TwoColumnAlt>
      <TwoColumn
        subhead="Create Your Card"
        head="Step 2"
        body="Write a message and sign your name! When I write thank you cards to Soldiers and Veterans, I always include how thankful I am for their service. Coming from a military based family, I have learned the importance of never forgetting the sacrifice each Soldier makes for our freedom. It is my goal to make sure each Soldier is appreciated and never forgotten. Get creative and write something meaningful to ensure they feel appreciated. "
        reverse={true}
        fluid={step2.fluid}
      />
      <TwoColumn
        subhead="Send it off"
        head="Step 3"
        body="Send it off! There are many ways to give out thank you cards. My favorite ways are including cards in care packages, giving them to veterans at your local Veteran home, or by giving your thank you card to a soldier you know. "
        fluid={step3.fluid}
      />
      <Support carePackage={carePackages.fluid} events={events.fluid} />
      <Subscription body="Get important updates about my mission and more fun activities just like this straight to your inbox. No spam, I promise." />
      <Social />
    </Layout>
  )
}

export default EducationPage
