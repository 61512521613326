import React from "react"
import { styled } from "linaria/react"
import Button from "../Elements/Button"

const Wrapper = styled.section`
  @media screen and (max-width: 480px) {
    flex-direction: column-reverse;
    margin: ${props => (props.first ? "50px 0 100px 0" : "0 0 50px 0")};
  }
  @media screen and (max-width: 900px) and (min-width: 481px) {
    flex-direction: column-reverse;
    margin: 0 0 70px 0;
  }
  @media screen and (max-width: 1200px) and (min-width: 901px) {
    margin: 50px 0 150px 0;
  }
  margin: 0px 0 150px 0;
  display: flex;
  flex-direction: ${props => (props.reverse ? "row-reverse" : "row")};
  width: 100%;
  justify-content: center;
  align-items: center;
`

const Text = styled.div`
  width: 50%;
  margin: 0 55px;
  display: flex;
  flex-direction: column;
  span {
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 300;
    margin-bottom: 10px;
  }
  h2 {
    font-size: 35px;
    text-transform: uppercase;
  }
  p {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 55px;
    font-weight: 300;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    margin: 0;
    padding: 15px 15px;
    p {
      font-size: 14px;
      line-height: 28px;
    }
  }
  @media screen and (max-width: 900px) and (min-width: 481px) {
    width: 90%;
    padding: 20px 0;
  }
  @media screen and (max-width: 1200px) and (min-width: 901px) {
    margin: 0 25px;
  }
`

const Right = styled.div`
  @media screen and (max-width: 480px) {
    width: 100%;
    padding: 0 15px 0 15px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 900px) and (min-width: 481px) {
    width: 100%;
    margin-top: 5%;
    max-height: 600px;
    padding: 0 20px 0 20px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 1200px) and (min-width: 901px) {
    display: flex;
    min-height: 100%;
    margin-bottom: auto;
  }
  padding: 0 55px 0 0;
  min-width: 50%;
`

const Divider = styled.div`
  height: 1px;
  background: #000;
  width: 100px;
  margin-bottom: 25px;
`

const TwoColumn = props => {
  return (
    <Wrapper
      first={props.first}
      style={props.style}
      className={props.className}
      reverse={props.reverse || false}
    >
      <Text>
        {props.subhead ? <span>{props.subhead}</span> : null}
        {props.head ? <h2>{props.head}</h2> : null}
        <Divider />
        {props.body ? <p>{props.body}</p> : null}
        {props.link ? (
          <Button link={props.link} text={props.buttonText} />
        ) : null}
      </Text>
      <Right>{props.children}</Right>
    </Wrapper>
  )
}

export default TwoColumn
