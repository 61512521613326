import React, { useState, useEffect } from "react"
import { styled } from "linaria/react"
import Img from "gatsby-background-image"
import Sheet1 from "../../images/Coloring Sheets/Coloring-Sheet-1.pdf"
import Sheet2 from "../../images/Coloring Sheets/Coloring-Sheet-2.pdf"
import Sheet1Img from "../../images/Coloring Sheets/Coloring-Sheet-1.jpg"
import Sheet2Img from "../../images/Coloring Sheets/Coloring-Sheet-2.jpg"
import Arrow from "../../images/Icons/keyboard_arrow_right.svg"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  max-width: 617px;
  @media screen and (max-width: 900px) {
    max-width: 100%;
  }
`

const Background = styled.img`
  @media screen and (max-width: 480px) {
    height: 300px;
  }
  @media screen and (max-width: 900px) and (min-width: 481px) {
    height: 400px;
  }
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 400px;
  transition: all 0.5s ease-out;
`

const Bottom = styled.div`
  background-color: #fff;
  padding: 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
`

const Horizontal = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-right: 15px;
  }
  > *:last-child {
    margin-right: 0px;
  }
  @media screen and (max-width: 365px) {
    > *:first-child {
      margin-right: 0px;
      margin-bottom: 10px;
    }
  }
`

const Content = styled.div`
  @media screen and (max-width: 900px) {
    max-width: none;
  }
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  > span {
    text-transform: uppercase;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 365px) {
    ${Horizontal}:nth-child(2) {
      flex-direction: column;
      width: 100%;
    }
  }
`

const StyledButton = styled.button`
  @media screen and (max-width: 365px) {
    width: 100%;
  }
  border: 1px solid #242424;
  background: #242424;
  color: #fff;
  width: 150px;
  height: 40px;
  text-transform: uppercase;
  &.alt {
    background: #fff;
    border: 1px solid #242424;
    color: #242424;
  }
  > a {
    text-decoration: none;
    color: inherit;
  }
`

const Circle = styled.div`
  transition: all 0.5s ease-out;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background: #ccc;
  &.active {
    background: #242424;
  }
`

const BlankButton = styled.button`
  background: #fff;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ColoringSheetCarousel = props => {
  const coloringSheets = props.coloringSheets
  const sheet1 = {
    src: Sheet1Img,
    title: coloringSheets[0].title,
    link: Sheet1,
  }
  const sheet2 = {
    src: Sheet2Img,
    title: coloringSheets[1].title,
    link: Sheet2,
  }
  const [state, setState] = useState(sheet1)
  const [activeIndex, setActiveIndex] = useState(0)

  //Set the inverse activeIndex since we clicked an arrow
  const arrowClick = () => {
    if (activeIndex === 0) {
      setActiveIndex(1)
      setState(sheet2)
    } else {
      setActiveIndex(0)
      setState(sheet1)
    }
  }

  // Since we clicked a specific circle, set that circle to the correct corresponding index, 0 or 1
  const circleClick = circleIndex => {
    if (activeIndex !== circleIndex) {
      setActiveIndex(circleIndex)
      if (circleIndex === 0) {
        setState(sheet1)
      } else setState(sheet2)
    }
  }
  /*
  let background = [
    `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.14) 63.4%, rgba(0, 0, 0, 0.76) 100%)`,
    state.fluid,
  ]*/
  useEffect(() => {
    //background[1] = state.fluid
  })
  return (
    <Wrapper>
      <Background src={state.src} />
      <Bottom>
        <Content>
          <span>{state.title}</span>
          <Horizontal>
            <StyledButton>
              <a href={state.link} download>
                Download
              </a>
            </StyledButton>
            <StyledButton className={"alt"}>
              <a href={state.link} target="_blank" rel="noreferrer">
                View
              </a>
            </StyledButton>
          </Horizontal>
          <Horizontal style={{ marginTop: "15px" }}>
            <BlankButton onClick={() => arrowClick()}>
              <img alt="Previous Slide Arrow" src={Arrow} />
            </BlankButton>
            <Circle
              onClick={() => circleClick(0)}
              className={activeIndex === 0 ? "active" : ""}
            />
            <Circle
              onClick={() => circleClick(1)}
              className={activeIndex === 1 ? "active" : ""}
            />
            <BlankButton onClick={() => arrowClick()}>
              <img
                alt="Next Slide Arrow"
                src={Arrow}
                style={{ transform: "scaleX(-1)" }}
              />
            </BlankButton>
          </Horizontal>
        </Content>
      </Bottom>
    </Wrapper>
  )
}

export default ColoringSheetCarousel
