import React from "react"
import PropTypes from "prop-types"
import { styled } from "linaria/react"
import Button from "../Elements/Button"
import Img from "gatsby-background-image"

const Wrapper = styled.section`
  @media screen and (max-width: 900px) {
    min-height: 0;
    margin-bottom: 100px;
  }
  @media screen and (max-width: 1024px) and (min-width: 901px) {
    min-height: 550px;
  }
  display: flex;
  min-height: 750px;
  width: 100%;
  position: relative;
`
const StyledImg = styled(Img)`
  @media screen and (max-width: 900px) {
    &::before,
    &::after {
      background-position: 70% 0%;
      background-size: 200px;
      background-repeat: no-repeat;
      max-height: 40vh;
    }
  }
  @media screen and (max-width: 1024px) and (min-width: 901px) {
    &::before,
    &::after {
      background-position: 60% 0%;
      background-repeat: no-repeat;
    }
  }
  &::before,
  &::after {
    background-size: cover;
    background-repeat: no-repeat;
  }
  @media screen and (min-width: 900px) {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
`
const Content = styled.div`
  @media screen and (max-width: 900px) {
    padding: 15px 15px 0 15px;
    width: 100%;
    padding-top: 40vh;
    background: #fff;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 28.65%,
      #ffffff 40vh
    );
  }
  @media screen and (max-width: 1280px) and (min-width: 901px) {
    padding: 0 25px;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  z-index: 1;
  margin: ${props => props.margin || "0 auto 0 0"};
  background: rgba(255, 255, 255, 0.9);
  padding: 0 55px;
  h1 {
    @media screen and (max-width: 480px) {
      font-size: 35px;
    }
    @media screen and (max-width: 1280px) and (min-width: 481px) {
      font-size: 37px;
    }
    margin-top: 10px;
    font-size: 45px;
    font-weight: 600;
    text-transform: uppercase;
  }
  p {
    @media screen and (max-width: 480px) {
      font-size: 14px;
    }
    @media screen and (max-width: 1024px) and (min-width: 481px) {
      font-size: 14px;
    }
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 70px;
    line-height: 30px;
  }
`

const Divider = styled.div`
  @media screen and (max-width: 480px) {
    margin-bottom: 15px;
  }
  @media screen and (max-width: 1024px) and (min-width: 481px) {
    margin-bottom: 15px;
  }
  height: 1px;
  background: #000;
  width: 100px;
  margin-bottom: 25px;
`

const Text = styled.div`
  margin: auto;
  > span {
    @media screen and (max-width: 480px) {
      display: none;
    }
    font-weight: 300;
    font-size: 25px;
    text-transform: uppercase;
  }
`
const BasicHero = props => {
  return (
    <Wrapper>
      <StyledImg
        fadeIn={false}
        loading={"eager"}
        fluid={props.fluid}
        style={{ backgroundPosition: "none", backgroundSize: "none" }}
      >
        <Content margin={props.margin}>
          <Text>
            <span>{props.subtitle}</span>
            <h1>{props.title}</h1>
            <Divider />
            <p>{props.body}</p>
            {props.cta || (
              <Button
                text={props.buttonText}
                link={props.link}
                style={{ width: "100%" }}
              />
            )}
          </Text>
        </Content>
      </StyledImg>
    </Wrapper>
  )
}

BasicHero.propTypes = {
  fluid: PropTypes.any.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  link: PropTypes.string,
  cta: PropTypes.any,
}

export default BasicHero
