import React from "react"
import { styled } from "linaria/react"
import Button from "../Elements/Button"
import Img from "gatsby-image"

const Heading = styled.h2`
  @media screen and (max-width: 900px) {
    margin-top: 50px;
    font-size: 35px;
  }
  @media screen and (max-width: 480px) {
    margin-top: 20px;
    font-size: 30px;
    line-height: 40px;
  }
  text-transform: uppercase;
  text-align: center;
  margin: auto;
  font-weight: 600;
  font-size: 45px;
  line-height: 55px;
  color: #fff;
`

const Hr = styled.hr`
  margin-top: 25px;
  margin-bottom: 25px;
  max-width: 150px;
  width: 130px;
  color: #fff;
`

const Vertical = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #242424;
  margin-bottom: 250px;
  padding: 50px 15px;
  > p {
    text-align: center;
    max-width: 700px;
    color: #fff;
    margin: auto;
    font-weight: 300;
    margin-bottom: 60px;
    line-height: 30px;
  }
  @media screen and (max-width: 900px) {
    padding: 25px 0;
  }
  @media screen and (max-width: 480px) {
    margin-bottom: 150px;
    padding: 25px 0;
    p {
      font-size: 14px;
      line-height: 28px;
    }
  }
`

const Horizontal = styled.div`
  @media screen and (max-width: 768px) {
    flex-direction: column;
    > * {
      margin-right: 0;
      margin-bottom: 30px;
    }
    > *:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
  display: flex;
  margin-top: 30px;
  padding: 0 15px;
  margin-bottom: -70px;
  @media screen and (min-width: 769px) {
    > * {
      margin-right: 30px;
    }
    > *:last-child {
      margin-right: 0;
    }
  }
`

const Image = styled(Img)`
  object-fit: cover;
  width: 100%;
  height: 350px;
`

const Box = styled.div`
  width: 100%;
  padding: 10px 10px 25px 10px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  > h3 {
    font-size: 24px;
    margin-top: 20px;
    font-weight: 600;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  > p {
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    font-weight: 300;
    margin: 20px 0 35px;
  }
`

const Support = props => {
  return (
    <Vertical>
      <Heading> Want More Ways to Support?</Heading>
      <Hr />
      <p>
        Looking for other ways to support my mission? Create a care package or
        find a volunteer opportunity at one of the upcoming events!
      </p>
      <Horizontal>
        <Box>
          <Image fluid={props.carePackage} />
          <h3>Care Packages</h3>
          <p>
            I have created a care package drive to send to active soldiers
            overseas. Follow along to see what items are most needed and where
            to send them.
          </p>
          <Button text={"Make a donation"} link="/mission#donate" />
        </Box>
        <Box>
          <Image fluid={props.events} />
          <h3>Events</h3>
          <p>
            Volunteering at events is one of the best ways to show your
            appreciation. Click the link to see the upcoming events supporting
            our soldiers and veterans.
          </p>
          <Button text={"Upcoming Events"} link="/events/" />
        </Box>
      </Horizontal>
    </Vertical>
  )
}

export default Support
